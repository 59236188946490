<template>
  <div>
    <el-page-header @back="goBack" :content="title">
    </el-page-header>
     <el-divider></el-divider>
    <el-card class="box-card" >
      <el-form ref="form" :model="model" label-width="120px">
        <form-gen :form-desc="formDesc" :model="model" />
      </el-form>
      <el-form :model="skillsModels" ref="skillsForm">
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column
            prop="label"
            label="Skill"
            width="250">
            <template #default="scope">
              <el-alert :title="nonFieldErrors(scope)" type="error"
                v-if="nonFieldErrors(scope)">
              </el-alert>
              {{ scope.row.label }}
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            label="Channel"
            width="250">
            <template #default="scope">
              {{ scope.row.code }}
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="Status"
            width="250">
            <template #default="scope">
              {{ scope.row.status }}
            </template>
          </el-table-column>
          <el-table-column
            prop="phoneNumber"
            label="Phone Number">
            <template #default="scope">
              <el-alert :title="fieldErrors(scope, 'phoneNumber')" type="error"
                v-if="fieldErrors(scope, 'phoneNumber')">
              </el-alert>
              <el-form-item label=" " :prop="'phoneNumber'">
                <el-input v-model="scope.row.phoneNumber"
                  :disabled="scope.row.setupDone">
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column>
            <template #default="scope">
              <el-button type="primary"
                :disabled="scope.row.setupDone"
                @click="sendWhatsAppPhone(scope.row)"
              >
                Send
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import { HttpError } from '@/types/errors'
import ModelView from '@/components/ModelView.vue'
import FormGen from '@/components/FormGen.vue'
import { InputKind, FormDesc, FormModel } from '@/types/form'
import {
  Customer,
  CustomerChannel,
  CustomerChannels,
  CustomerSkill,
  CustomerSkillRow
} from '@/types/customerState'

@Options({
  components: {
    FormGen
  }
})
export default class CustomersEdit extends mixins(ModelView) {
  private formDesc: FormDesc = {
    nonFieldErrors: null,
    fieldsets: [{
      fields: [{
        disabled: true,
        errors: null,
        kind: InputKind.InputHidden,
        label: 'Id',
        name: 'id'
      }, {
        disabled: true,
        errors: null,
        kind: InputKind.InputText,
        label: 'Name',
        name: 'name'
      }, {
        disabled: true,
        errors: null,
        kind: InputKind.InputText,
        label: 'Offer',
        name: 'offer'
      }, {
        disabled: true,
        errors: null,
        kind: InputKind.InputText,
        label: 'Date',
        name: 'createdOn'
      }]
    }]
  }

  private model: FormModel = {
    id: '',
    name: '',
    offer: '',
    createdOn: ''
  }

  private skillsForms: Array<FormDesc> = []

  private skillsModels: Array<FormModel> = []

  get id (): string {
    return this.$route.params.id.toString()
  }

  get modelChannels (): CustomerChannels | null {
    return this.$store.getters[this.getPath('get', 'objChannels')]
  }

  get modelData (): Customer | null {
    return this.$store.getters[this.getPath('get', 'obj')]
  }

  get readyState (): boolean {
    return this.modelData !== null && this.modelChannels !== null
  }

  get tableData (): Array<CustomerSkillRow> | null {
    const table: Array<CustomerSkillRow> = []
    if (this.modelData && this.modelData.skills && this.modelChannels) {
      const skills = JSON.parse(JSON.stringify(this.modelData.skills))
      skills.sort(this.compareTable)
      skills.forEach((cSkill: CustomerSkill) => {
        let code = ''
        let phoneNumber = ''
        let status = ''
        let setupDone = cSkill.setupDone
        if (this.modelChannels) {
          const channel: CustomerChannel | undefined = this.modelChannels.find(
            (chann: CustomerChannel) => (
              chann.skill === cSkill.id && chann.code === 'whatsapp'))
          if (channel) {
            code = channel.code
            status = channel.status
            if (channel.params && channel.params.full && channel.params.full.phone_number) {
              phoneNumber = channel.params.full.phone_number
            }
            if (channel.status === 'active' || channel.status === 'done' ) {
              setupDone = true
            }
            table.push({
              id: cSkill.id,
              label: cSkill.label,
              code: code,
              status: status,
              phoneNumber: phoneNumber,
              setupDone: setupDone
            })
          }
        }
      })
    }

    return table
  }

  get title (): string {
    let title = 'Customers'
    if (this.modelData) {
      title += ' : ' + this.modelData.name
    }
    return title
  }

  nonFieldErrors (scope: any): string | null {
    let errMsg = null
    if (this.skillsForms[scope.$index].nonFieldErrors) {
      const errs = this.skillsForms[scope.$index].nonFieldErrors
      if (errs) {
        errMsg = errs.join('<br />')
      }
    }
    return errMsg
  }

  fieldErrors (scope: any, name: string): string | null {
    let errMsg = null
    if (this.skillsForms[scope.$index]) {
      this.skillsForms[scope.$index].fieldsets.forEach((fieldset) => {
        fieldset.fields.forEach((field) => {
          if (field.name === name && field.errors) {
            errMsg = field.errors.join('<br />')
          }
        })
      })
    }
    return errMsg
  }

  compareTable (a: CustomerSkillRow, b: CustomerSkillRow) {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1
    }
    return 0
  }

  fetchObj (): void {
    this.$store.dispatch(this.getPath('fetch', 'obj'), { id: this.id })
      .catch((e: HttpError) => {
        this.setErrors(e)
      })

    this.$store.dispatch(this.getPath('fetch', 'objChannels'), { id: this.id })
      .catch((e: HttpError) => {
        this.setErrors(e)
      })
  }

  resetErrors (formDesc: FormDesc): void {
    if (formDesc) {
      formDesc.nonFieldErrors = null
      formDesc.fieldsets.forEach((fieldset) => {
        fieldset.fields.forEach((field) => {
          field.errors = null
        })
      })
    }
  }

  sendWhatsAppPhone (skill: CustomerSkillRow): void {
    let idx = 0
    this.skillsModels.forEach((cSkill, index) => {
      if (cSkill.id === skill.id) {
        idx = index
      }
    })
    this.resetErrors(this.skillsForms[idx])
    this.$store.dispatch(this.getPath('send', 'skill'), skill)
      .catch((e: HttpError) => {
        this.setErrors(e, this.skillsForms[idx])
        // console.log('<<<<', this.skillsForms[idx].fieldsets[0].fields[1])
      })
  }

  setupSkillsForms (): Array<FormDesc> {
    const forms: Array<FormDesc> = []
    if (this.modelData && this.modelData.skills) {
      const skills = JSON.parse(JSON.stringify(this.modelData.skills))
      skills.sort(this.compareTable)
      skills.forEach(() => {
        forms.push({
          nonFieldErrors: null,
          fieldsets: [{
            fields: [{
              disabled: true,
              errors: null,
              kind: InputKind.InputHidden,
              label: 'Id',
              name: 'id'
            }, {
              disabled: true,
              errors: null,
              kind: InputKind.InputText,
              label: 'Phone Number',
              name: 'phone'
            }]
          }]
        })
      })
    }
    return forms
  }

  setupSkillsModels (): Array<FormModel> {
    const model: Array<FormModel> = []
    if (this.modelData && this.modelData.skills) {
      const skills = JSON.parse(JSON.stringify(this.modelData.skills))
      skills.sort(this.compareTable)
      skills.forEach((cSkill: CustomerSkill) => {
        let phoneNumber = ''
        if (this.modelChannels) {
          const channel: CustomerChannel | undefined = this.modelChannels.find(
            (chann: CustomerChannel) => chann.skill === cSkill.id)
          if (channel && channel.code === 'whatsapp' && channel.params &&
              channel.params.full && channel.params.full.phone_number) {
            phoneNumber = channel.params.full.phone_number
          }
        }
        model.push({
          id: cSkill.id,
          phoneNumber: phoneNumber,
          setupDone: cSkill.setupDone
        })
      })
    }
    return model
  }

  created (): void {
    this.$store.commit(this.getPath('set', 'obj'), null)
    this.$store.commit(this.getPath('set', 'objChannels'), null)
    this.fetchObj()
  }

  @Watch('readyState', {
    deep: true,
    immediate: true
  })
  watchReadyState (val: boolean) {
    if (val !== false && this.modelData !== null && this.modelChannels !== null) {
      this.skillsForms = this.setupSkillsForms()
      this.skillsModels = this.setupSkillsModels()
      if (this.modelData !== null) {
        this.model = this.modelData
      }
    }
  }
}
</script>

<style lang="scss">
table.el-table__body .el-form-item {
  margin-bottom: 0px;
}
</style>
